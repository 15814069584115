import styled from "styled-components";
import React from "react";
import { LegendCategory, LegendContainer, LegendImages } from "./style";
import tire from "../images/tire.png";
import bottle from "../images/bottle.png";
import bag from "../images/bag.png";
import bluebottle from "../images/bluebottle.png";
import can from "../images/can.png";
import key from "../images/key.png";
import conserve from "../images/conserve.png";
import folie from "../images/foil.png";
import bottle1 from "../images/bottle1.png";
import bottle2 from "../images/bottle2.png";
import pahar from "../images/waterglass.png";
import pahar2 from "../images/wineglass.png";

const StatWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  > div > button {
    width: 200px;
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: green;
    border: none;
    border-radius: 20px;
    padding: 16px 32px;
    margin: 12px;

    :hover {
      background: #388638;
      cursor: pointer;
    }
  }
  @media (max-width: 1200px) {
    gap: 20px;
    > div > button {
      font-size: 25px;
      padding: 12px 24px;
    }
  }
`;
const HelpButton = styled.button`
  display: none;
  font-size: 40px;
  width: 200px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background: green;
  border: none;
  border-radius: 20px;
  padding: 16px 32px;
  margin: 12px;

  :hover {
    background: #388638;
    cursor: pointer;
  }
  @media (max-width: 1200px) {
    display: block;
  }
`;
const StartInfo = styled.div`
  max-width: 700px;
  text-transform: uppercase;
  color: white;
  text-shadow: 1px 1px 7px rgba(0, 0, 0, 1);
  > h1 {
    margin-bottom: 40px;
    font-size: 60px;
  }
  > h3 {
    font-size: 25px;
  }
  @media (max-width: 1200px) {
    padding: 0 20px;
    > h1 {
      margin-bottom: 20px;
      font-size: 30px;
    }
    > h3 {
      font-size: 15px;
    }
  }
`;

interface startProps {
  useStartedGame: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Start = (props: startProps) => {
  const [helpVisible, setHelpVisible] = React.useState(false);

  let plasticGarbage = [tire, bottle, bag, bluebottle];
  let metalGarbage = [can, key, conserve, folie];
  let glassGarbage = [bottle1, bottle2, pahar, pahar2];
  return (
    <StatWrapper>
      {!helpVisible ? (
        <>
          <StartInfo>
            <h1>TINE APELE CURATE</h1>
            <h3>
              Poluarea apei este o problema majora in Romania, afectand raurile,
              lacurile si chiar si apele subterane. Industria, agricultura si
              infrastructura urbana sunt principalele surse de poluare, iar
              acest lucru poate duce la efecte negative asupra sanatatii umane
              si a mediului inconjurator.
            </h3>
            <h3>
              Colectarea selectiva a deseurilor este importanta pentru protectia
              mediului si pentru a reduce impactul negativ al deseurilor asupra
              sanatatii oamenilor si a ecosistemelor.
            </h3>
          </StartInfo>
          <div>
            <button onClick={() => props.useStartedGame(true)}>Play</button>
            <HelpButton onClick={() => setHelpVisible(true)}>Ajutor</HelpButton>
          </div>
        </>
      ) : (
        <LegendContainer width="90%">
          <LegendCategory>
            <p style={{ color: "#e86800" }}> Deseuri de plastic:</p>
            <LegendImages>
              {plasticGarbage.map((url, index) => (
                <img src={url} alt={`garbage_${index}`} />
              ))}
            </LegendImages>
          </LegendCategory>
          <LegendCategory>
            <p style={{ color: "#ffff00" }}> Deseuri de metal:</p>
            <LegendImages>
              {metalGarbage.map((url, index) => (
                <img src={url} alt={`garbage_${index}`} />
              ))}
            </LegendImages>
          </LegendCategory>
          <LegendCategory>
            <p style={{ color: "#00bb00" }}> Deseuri de sticla:</p>
            <LegendImages>
              {glassGarbage.map((url, index) => (
                <img src={url} alt={`garbage_${index}`} />
              ))}
            </LegendImages>
          </LegendCategory>
          <LegendCategory>
            <p
              style={{
                textTransform: "initial",
                fontSize: "20px",
                color: "white",
                margin: "20px 0 0 0",
                padding: "0 15px",
              }}
            >
              Controlul Jocului: Poți alege coșul de gunoi potrivit folosind
              mouse-ul sau tastele de pe tastatură. Pentru a selecta coșul de
              plastic, apasă tasta 'a'. Pentru a selecta coșul de metal, apasă
              tasta 's'. Pentru a selecta coșul de sticla, apasă tasta 'd'.
            </p>
          </LegendCategory>
          <HelpButton
            style={{ margin: "30px auto" }}
            onClick={() => setHelpVisible(false)}
          >
            Inapoi
          </HelpButton>
        </LegendContainer>
      )}
    </StatWrapper>
  );
};
